import React, { Component } from 'react';
import { userService } from '../_services';
import '../mysqpaymentform.css';

export default class PaymentFormNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cardBrand: "",
            nonce: undefined,
            googlePay: false,
            applePay: false,
            masterpass: false,
            UserId: '',
            Fname: '',
            Lname: '',
            Address1: '',
            Address2: '',
            State: '',
            City: '',
            Zip: '',
            Email: '',
            errormsg: '',
            SuccessMessage: '',
            loader: false,
            sqinput: []
        }
        //this.requestCardNonce = this.requestCardNonce.bind(this);
        this.displayPaymentResults = this.displayPaymentResults.bind(this);
        this.tokenize = this.tokenize.bind(this);
        this.handlePaymentMethodSubmission = this.handlePaymentMethodSubmission.bind(this);
    }

    async initializeCard(payments) {
        let card = await payments.card();
        await card.attach(this.refs.cardContainer);
        return card;
    }

    /*async SendCardOnEmail(UserId, Address1, Address2, State, City, Zip) {
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        const UserName = Usermeta_Data.split(':')[1]
        const ProductName = this.props.ProductName
        const Quantity = this.props.quantity
        const GroupNumber = this.props.group_number
        const CardBase64 = this.props.base64blob
        const ShippingAddress = Address1 + ', ' + Address2 + ', ' + City + ', ' + State + '-' + Zip
        this.setState({ ClientMessage: "", errormsg: '' })
        userService.EmailOrderCard(UserId, UserName, ProductName, Quantity, GroupNumber, ShippingAddress, CardBase64)
            .then(
                apidata => {
                    try {
                        if (apidata.length > 0) {
                            if (apidata[0].code === "-1") {
                                //let errorMsg = ''
                                //let apiMsg = apidata[0].message
                                //this.setState({ ClientMessage: errorMsg, loader: false })
                            }
                            this.setState({ loader: false })
                        } else {
                            this.setState({ ClientMessage: "Failed", loader: false })
                        }
                    }
                    catch (error) {
                        this.setState({ ClientMessage: "Failed", loader: false })
                        this.setState({ loader: false })
                    }
                }
            );
    }*/

    async createPayment(UserId, idempotency_key, source_id, Fname, Lname, Address1, Address2, State, City, Zip, Email) {
        this.setState({ ClientMessage: "", errormsg: '' })
        const ProductName = this.props.ProductName
        const Quantity = this.props.quantity
        const GroupNumber = this.props.group_number
        const CardBase64 = this.props.base64blob
        const ProductPrice = this.props.total_price
        userService.CreatePayment(UserId, idempotency_key, source_id, Fname, Lname, Address1, Address2, State, City, Zip, Email, GroupNumber, ProductName, Quantity, CardBase64, ProductPrice)
            .then(
                async apidata => {
                    try {
                        if (apidata.length > 0) {
                            if (apidata[0].code === "-1") {
                                let errorMsg = ''
                                let apiMsg = apidata[0].message
                                if (apiMsg === 'GENERIC_DECLINE') {
                                    errorMsg = 'Card number declined'
                                } else if (apiMsg === 'INVALID_EXPIRATION') {
                                    errorMsg = 'Card expiration date incorrect'
                                } else if (apiMsg === 'CVV_FAILURE') {
                                    errorMsg = 'Card CVV incorrect'
                                } else if (apiMsg === 'ADDRESS_VERIFICATION_FAILURE') {
                                    errorMsg = 'Card postal code incorrect'
                                } else {
                                    errorMsg = apiMsg
                                }
                                this.setState({ ClientMessage: errorMsg, loader: false })
                            }
                            else {
                                this.setState({ ClientMessage: "Your payment is success", loader: false })
                                /*if (this.props.forCard) {
                                    await this.SendCardOnEmail(UserId, Address1, Address2, State, City, Zip )
                                }*/
                            }
                            this.setState({ loader: false })
                        }
                    }
                    catch (error) {
                        this.setState({ ClientMessage: "Failed", loader: false })
                        this.setState({ loader: false })
                    }
                }
            );
    }

    async tokenize(paymentMethod) {
        this.setState({ errormsg: '', ClientMessage: '', SuccessMessage: '' })
        const tokenResult = await paymentMethod.tokenize();
        if (tokenResult.status === 'OK') {
            return tokenResult.token;
        } else {
            let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
            if (tokenResult.errors) {
                errorMessage += ` and errors: ${JSON.stringify(
                    tokenResult.errors
                )}`;
            }
            this.setState({ errormsg: tokenResult.errors[0].message })
            throw new Error(errorMessage);
            return false
        }
    }
    async handlePaymentMethodSubmission(event, paymentMethod, locationId) {
        event.preventDefault();
        try {
            this.setState({ loader: true })
            event.target.disabled = true;
            const token = await this.tokenize(paymentMethod);
            const idempotency_key = this.uuidv4();
            let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
            let UserId = Usermeta_Data.split(':')[0];
            //const paymentResults = await this.createPayment(token, idempotency_key, locationId);
            const paymentResults = await this.createPayment(UserId, idempotency_key, token, this.state.Fname, this.state.Lname, this.state.Address1, this.state.Address2, this.state.State, this.state.City, this.state.Zip, this.state.Email);
            //(UserId, idempotency_key, source_id, Fname, Lname, Address1, Address2, State, City, Zip, Email)
            await this.displayPaymentResults('SUCCESS');
            //this.setState({ loader: false })

            //console.debug('Payment Success', paymentResults);
        } catch (e) {
            this.setState({ loader: false })
            if (e.errors != undefined && e.errors !== null) {
                this.setState({ errormsg: e.errors[0]["message"].split('.')[0] })
            }
            //e.errors[0]["message"].split('.')[0]
            event.target.disabled = false;
            this.displayPaymentResults('FAILURE');
            //console.error(e.message);

        }
    }

    displayPaymentResults(status) {
        //this.setState({ SuccessMessage: '' })
        //this.setState({ errormsg: '' })
        const statusContainer = document.getElementById(
            'payment-status-container'
        );
        if (status === 'SUCCESS') {
            statusContainer.classList.remove('is-failure');
            statusContainer.classList.add('is-success');
            //this.setState({ SuccessMessage: 'Payment is success. Please wait for a while.' })
        } else {
            statusContainer.classList.remove('is-success');
            statusContainer.classList.add('is-failure');
            //this.setState({ errormsg: 'Payment is failed. Please try again later' })
        }

        statusContainer.style.visibility = 'visible';
    }

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    componentDidMount() {
        userService.GetPaymentStuff('')
            .then(
                async apidata => {
                    if (apidata.length > 0) {
                        try {
                            this.setState({ Fname: this.props.Fname, Lname: this.props.Lname, Address1: this.props.Address1, Address2: this.props.Address2, State: this.props.State, City: this.props.City, Zip: this.props.Zip, Email: this.props.Email });

                            if (!window.Square) {
                                throw new Error('Square.js failed to load properly');
                            }
                            this.paymentForm = this.props.paymentForm(apidata[0].applicationId, apidata[0].locationId);
                            let card;
                            try {
                                card = await this.initializeCard(this.paymentForm);
                            } catch (e) {
                                console.error('Initializing Card failed', e);
                                return;
                            }
                            const cardButton = document.getElementById(
                                'card-button'
                            );
                            let thisPoint = this
                            cardButton.addEventListener('click', async function (event) {
                                await thisPoint.handlePaymentMethodSubmission(event, card, apidata[0].locationId);
                            });
                        }
                        catch (error) {
                            this.setState({ errormsg: 'Payment Failed! Please try later', loader: false })
                        }
                    }
                }
            );
    }
    render() {
        return (
            <div className="container">
                <link rel="stylesheet" type="text/css" href="mysqpaymentform.css" />
                <div id="form-container">
                    <div id="sq-ccbox" className="sqbox">
                        <form id="payment-form">
                            <div id="card-container" ref="cardContainer"></div>
                        </form>
                        <div id="payment-status-container"></div>

                    </div>
                    <div className="footer">
                        <div className={this.state.loader ? 'yes_btn make-payment loader disabled' : 'yes_btn'} id="card-button">Pay ${this.props.forCard ? this.props.total_price : '89.95'}</div>
                        <div className="no_btn" onClick={this.props.toggle_Payment_pop}>Cancel</div>
                    </div>
                    <div className="errormsg" id="error">{this.state.errormsg}</div>
                    <div className="errormsg" id="error">{this.state.ClientMessage}</div>
                    {this.state.ClientMessage === "Your payment is success" ?
                        this.props.SuccessMessage(true)
                        : null}
                </div>
            </div>
        )
    }
}