import React, { Component } from "react"; // PropTypes commented because not in use
//import ptrx_logo from '../../images/ptrx_logo.png'; commented because not in use
import card_image_pharmacy from '../../images/cards_logos.png'
//import PricingTool from '../Affiliate_Url/Pricing_tool'; commented because not in use
//import bar_code from '../../images/barcode.png';commented because not in use
import jsPDF from 'jspdf'
import domtoimage from 'dom-to-image';
import $ from 'jquery';
import {QRCode} from "react-qr-svg";
import { userService } from "../../_services";
import Rxlogo from '../../images/logo-white.png';
import app_store from '../../images/app_store.svg';
import play_store from '../../images/play_store.svg';
import cvs from '../../images/cvs-card.png';
import wg from '../../images/walgreens.png';
import ra from '../../images/rite-aid.png';
import kroger from '../../images/kroger.png';
import wallmart from '../../images/wallmart-card.png';
import AppleWallet from "../../images/apple-wallet.svg";
import GoogleWallet from "../../images/google-wallet.svg";
import AppleModal from "../../components/Consumer/appleModal";
import Resources from "../../resources.json";

class CardPartial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Qrsubdomain: '',
            passtype: 'generic',
            deviceName: '',
            browserName: '',
            applePopup: false,
            shwoApplePopup: false
        }
        this.printIndivisual = this.printIndivisual.bind(this);
        this.downloaddoc = this.downloaddoc.bind(this);
        this.appleWalletPopup = this.appleWalletPopup.bind(this);
    }
  componentDidMount() {
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.GetSubDomin(Usermeta_Data.split(':')[0], '', '','Fetch')
        // get user device and browser name 
        let userAgent = window.navigator.userAgent;
        let devicetype = this.GetUserAgent(userAgent);
        let browsername = this.GetBrowserName(userAgent);
        this.setState({ deviceName: devicetype, browserName: browsername });
    }
   GetSubDomin(UserId, SubDomain, GroupNumber, Task_Name) {
       try {
            userService.PricingToolInitialStatus(UserId, SubDomain, GroupNumber, Task_Name)
                .then(
                    apidata => {
                        try {
                            if (apidata.length >= 0) {
                                let subdomain = '';
                                if (apidata.length === 0) {
                                    let subd = "http://bit.ly/RxHacker";
                                    this.setState({ Qrsubdomain: subd })
                                }
                                else {
                                    subdomain = apidata[0].subDomain;
                                    if (subdomain === null || subdomain === "" || subdomain == "undefined") {
                                        let subd = "http://bit.ly/RxHacker";
                                        this.setState({ Qrsubdomain: subd })
                                    } else {
                                        this.setState({ Qrsubdomain: subdomain })
                                    }
                                }
                            }
                        }
                        catch (error) {
                            let subd = "http://bit.ly/RxHacker";
                            this.setState({ Qrsubdomain: subd })
                        }
                    }
                );
        }
        catch (error) {
            let subd = "http://bit.ly/RxHacker";
            this.setState({ Qrsubdomain: subd })
        }
    }
    printIndivisual(event, data) {
        var printableElements = document.getElementById(event.target.parentElement.parentElement.nextElementSibling.children[0].getAttribute('id')).innerHTML;
        var originalContents = document.body.innerHTML;
        document.body.innerHTML = printableElements;
        window.print();
        document.body.innerHTML = originalContents;
        //window.location.href = window.location.href;
    }
    downloaddoc(id, name, event) {
        domtoimage.toPng(document.getElementById(id))
            .then(function (blob) {
                var pdf = new jsPDF('p', 'pt', [$('#' + id).outerWidth(), $('#' + id).outerHeight() - 20]);
                pdf.addImage(blob, 'PNG', 0, 0, $('#' + id).outerWidth(), $('#' + id).outerHeight());
                pdf.save(name+'.pdf');
            });

    }
    GetUserAgent(UserAgent_Raw) {
        if (/Mobi|Android/i.test(UserAgent_Raw)) {
            if (/iPhone|iPad|iPod/i.test(UserAgent_Raw)) {
                return 'iPhone';
            }
            else {
                return 'Android';
            }
        } else {
            return 'Desktop';
        }
    }
    GetBrowserName(UserAgent_Raw) {
        if (/Firefox/i.test(UserAgent_Raw)) {
            return 'Firefox';
        } else if (/Chrome/i.test(UserAgent_Raw)) {
            return 'Chrome';
        } else if (/Safari/i.test(UserAgent_Raw)) {
            return 'Safari';
        } else if (/MSIE|Trident/i.test(UserAgent_Raw)) {
            return 'Internet Explorer';
        } else if (/Edge/i.test(UserAgent_Raw)) {
            return 'Edge';
        } else if (/Opera|OPR/i.test(UserAgent_Raw)) {
            return 'Opera';
        } else if (/Brave/i.test(UserAgent_Raw)) {
            return 'Brave';
        } else if (/Vivaldi/i.test(UserAgent_Raw)) {
            return 'Vivaldi';
        } else if (/Yandex/i.test(UserAgent_Raw)) {
            return 'Yandex';
        } else if (/Chromium/i.test(UserAgent_Raw)) {
            return 'Chromium';
        } else {
            return 'Unknown';
        }
    }
    appleWalletPopup() {
        this.setState({
            applePopup: !this.state.applePopup
        });
    }
    openAppleLink = () => {
        let applePass = 'https://passes.23byt.es/passes/apple/apple_gn_20240515051426.pkpass';
        window.open(applePass, '_blank');
    }
    openGoogleLink = () => {
        let googlePass = 'https://pay.google.com/gp/v/save/eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJ3YWxsZXRAaG9uZXN0LXJ4LTIxNjMyMC5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsImF1ZCI6Imdvb2dsZSIsIm9yaWdpbnMiOlsid3d3LmV4YW1wbGUuY29tIl0sInR5cCI6InNhdmV0b3dhbGxldCIsInBheWxvYWQiOnsiZ2VuZXJpY0NsYXNzZXMiOlt7ImlkIjoiMzM4ODAwMDAwMDAyMjExNjIwNC4xZWM4MDU5MS04OTYyLTQ3YzUtODljZi1iNzIwNzU2MjY4YzMifV0sImdlbmVyaWNPYmplY3RzIjpbeyJjYXJkVGl0bGUiOnsiZGVmYXVsdFZhbHVlIjp7Imxhbmd1YWdlIjoiZW4tVVMiLCJ2YWx1ZSI6IlByZXNjcmlwdGlvbiBEaXNjb3VudCBjYXJkIn19LCJjbGFzc0lkIjoiMzM4ODAwMDAwMDAyMjExNjIwNC4xZWM4MDU5MS04OTYyLTQ3YzUtODljZi1iNzIwNzU2MjY4YzMiLCJoZWFkZXIiOnsiZGVmYXVsdFZhbHVlIjp7Imxhbmd1YWdlIjoiZW4tVVMiLCJ2YWx1ZSI6IlJ4SGFja2VyIn19LCJoZXhCYWNrZ3JvdW5kQ29sb3IiOiIjMTkzNTYwIiwiaWQiOiIzMzg4MDAwMDAwMDIyMTE2MjA0LjVlNDBmYWYzLTY0YzAtNDRkMC05M2Y4LTI0MmMxZjM2NTI4NSIsImxvZ28iOnsiY29udGVudERlc2NyaXB0aW9uIjp7ImRlZmF1bHRWYWx1ZSI6eyJsYW5ndWFnZSI6ImVuLVVTIiwidmFsdWUiOiJSeEhhY2tlciBjYXJkIGxvZ28ifX0sInNvdXJjZVVyaSI6eyJ1cmkiOiJodHRwczovL3Bhc3Nlcy4yM2J5dC5lcy9JY29ucy9yeGhhY2tlcndhbGxldF9wYXNzLnBuZyJ9fSwic3ViaGVhZGVyIjp7ImRlZmF1bHRWYWx1ZSI6eyJsYW5ndWFnZSI6ImVuLVVTIiwidmFsdWUiOiJUaGlzIGNhcmQgaXMgZnJlZS4gVGhpcyBpcyBub3QgaW5zdXJhbmNlLiJ9fSwidGV4dE1vZHVsZXNEYXRhIjpbeyJib2R5IjoiMDIzNTE4IiwiaGVhZGVyIjoiQklOIiwiaWQiOiJiaW4ifSx7ImJvZHkiOiJBUlgiLCJoZWFkZXIiOiJQQ04iLCJpZCI6InBjbiJ9LHsiYm9keSI6IlJYSDEyMzQiLCJoZWFkZXIiOiJHcm91cCAjIChBY2Nlc3MgQ29kZSkiLCJpZCI6Imdyb3VwIn0seyJib2R5IjoiMTAwLTQ1NDIiLCJoZWFkZXIiOiJNZW1iZXIgSUQiLCJpZCI6Im1lbWJlcmlkIn1dfV19fQ.ECT19BpcFBNaAMpn6m-nsxD3e_l6j5ucbjxuSd3QzK_Xv4YTfq4l_K5YqrmInoATl_EG4BcSIO-B_ZFSZmLuvRpwFDoTKLWrcA9z6GGvomIz3UtwNVy63p6XhuE4hhZk4VVVH9K39XsP8n-kyIm23_e7ia8Xw1aNKrwuCcxKeXeg56f5HNoaIa0UW4qb0a8JTFuIQy5zerdHHvAE9ld40FPqK1FVluIdCJoDXzsZJqKla5DUovpENgrQH6lfM_DFTU0uGME0-s5c2JKA2mqUQ1wVrQKyjiwyu1FmZgrlBCE2I3-Os5_DR1KeMgW4GEmevZH3X7cp8Og6vx7Qo5TOog';
        window.open(googlePass, '_blank');
    }
    render() {
        let deviceName = this.state.deviceName;
        let browserName = this.state.browserName;
        let showAppleWallet = true;
        let showGoggleWallet = true;
        if (deviceName == 'Android') {
            showAppleWallet = false;
        } else if (deviceName == 'iPhone') {
            showGoggleWallet = false;
        }
        const { classname, groupnumber, dateTime, forOrder } = this.props; //cardname,group,bin,pcn,memberid,totalCount, status commented because not in use
        let cardsData = [
            {
                cardname: 'Preferred Card',
                classname: '',
                bin: '023518', //'610301',
                pcn: 'ARX', //'HD',
            }]
        if (this.props.status === 'Yes') {
            cardsData = [
                {
                    cardname: 'Preferred Card',
                    classname: '',
                    bin: '023518', //'610301',
                    pcn: 'ARX', //'HD',
                },
                {
                    cardname: 'NVT Card',
                    classname: 'red',
                    bin: '015284',
                    pcn: 'NVT'
                },
                {
                    cardname: 'DST Card',
                    classname: 'orange',
                    bin: '015284',
                    pcn: 'DST'
                },
                {
                    cardname: 'CRX Card',
                    classname: 'blue',
                    bin: '015284',
                    pcn: 'CRX'
                }];


            //  const cardsData = [


            //{
            //    cardname: 'NVT Card',
            //    classname: 'red',
            //    bin: '015284',
            //    pcn: 'NVT'
            //},
            //{
            //    cardname: 'DST Card',
            //    classname: 'orange',
            //    bin: '015284',
            //    pcn: 'DST'
            //},
            //{
            //    cardname: 'CRX Card',
            //    classname: 'blue',
            //    bin: '015284',
            //    pcn: 'CRX'
            //}
        }
            return (
                <>
                    {cardsData.map((card, index) =>
                        <div className={forOrder ? "card print-order-cards" : "card"} id={groupnumber + index} key={index}>
                            {!forOrder ? <div className="header">
                                <h3>{card.cardname}</h3>
                                <div className="buttons">
                                    <div className="download" onClick={this.downloaddoc.bind(this, card.pcn + "_" + groupnumber + index, card.pcn + "_" + groupnumber)}></div>
                                    <div className="print" onClick={this.printIndivisual.bind(this)}></div>
                                </div>
                            </div> : null}
                            <div className="all_cards">
                                <div>
                                    <div className="cards glic_card">
                                        <div className="order_card card_front glic_card" style={{ borderRadius: this.props.cardStyle && this.props.cardStyle === 'Square' ? '0' : '16px'}}>
                                            <div className="side-icon"></div>
                                            <div className="card_header">
                                                <img src={Rxlogo} className="img-fluid card_logo" alt="NuLifeSpan Rx Logo" />
                                                <h2>Prescription Discount Card</h2>
                                            </div>
                                            <div className="card_body">
                                                <div className="member-info">
                                                    <div className="memberid">Member ID <span>{"AX" + dateTime.substring(dateTime.length - 6)}</span></div>
                                                    <p>Accepted at 35,000<br />pharmacies nationwide</p>
                                                </div>
                                                <div className="card_info">
                                                    <div>Bin: <span>{card.bin}</span></div>
                                                    <div>PCN: <span>{card.pcn}</span></div>
                                                    <div>Group: <span>{groupnumber}</span></div>
                                                </div>
                                            </div>
                                            <div className="card_footer">
                                                <div className="footer-left">Show this card to save up to 80% on your prescriptions</div>
                                                <div className="footer-right">
                                                    <div className="insurance-text">This card is free. This is not insurance.</div>
                                                    <p>Customer Service: 1-800-999-3053</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="order_card card_back glic_card" style={{ borderRadius: this.props.cardStyle && this.props.cardStyle === 'Square' ? '0' : '16px' }}>
                                            <div className="card_body">
                                                <div className="left_info">
                                                    <div className="col-6">
                                                        <strong>Member Instructions</strong><br />
                                                        1. Present card to your pharmacist.<br />
                                                        2. Ask for discount on each prescription<br />
                                                        3. SAVE UP TO 80%*<br />
                                                        4. Use over and over! This card does not expire
                                                    </div>
                                                    <div className="col-6">
                                                        <strong>Instrucciones Miembros</strong><br />
                                                        1. Presente la tarjeta su farmaceutico<br />
                                                        2. Pedir descuento en cada recata<br />
                                                        3. AHORRE HASTA UN 80%**<br />
                                                        4. jUtilice una y otra vez! Esta tarjeta no caduca.
                                                    </div>
                                                    <div className="col-12">
                                                        <p>*Prescription savings vary by prescription and by pharmacy, and may reach up to 80% off of the cash price. **Los ahorros en recetas varian segun la receta y la farmacia, y pueden alcanzar hasta un 80% de descuento sobre el precio en efectivo.</p>
                                                        <p>Visit nulifespanrx.com, download the NuLifeSpan Rx app, or call 800-984-4031 for more information, to report any concerns and to find participating pharmacies near you! The NuLifeSpan Rx Program, prices, list of covered drugs, and participating pharmacies are subject to change. By using this card, you have agreed to the terms and conditions found at nulifespanrx.com/terms. The NuLifeSpan Rx Program is provided by NuLifeSpan Rx LLC, PO Box 1703, Allen, TX 75013.</p>
                                                    </div>
                                                    <div className="seperator-text"><span>This card is free. This is not insurance</span></div>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <div className="logos">
                                                    <img src={cvs} alt="CVS Pharmacy" />
                                                    <img src={wg} alt="Wallgreens" />
                                                    <img src={ra} alt="Rite Aid" />
                                                    <img src={kroger} alt="Wings" />
                                                    <img src={wallmart} alt="Wallmart" />
                                                </div>
                                                <div className="right_info">
                                                    {
                                                        this.props.isLinked ?
                                                            <div>
                                                                {
                                                                    showAppleWallet &&
                                                                    <img className="apple-wallet" onClick={this.state.shwoApplePopup ? this.appleWalletPopup : this.openAppleLink} src={AppleWallet} alt="apple icon" />
                                                                }
                                                                {
                                                                    showGoggleWallet &&
                                                                    <img className="google-wallet" onClick={this.openGoogleLink} src={GoogleWallet} src={GoogleWallet} alt="google icon" />
                                                                }
                                                                <p>
                                                                    <strong>www.nulifespanrx.com</strong> &copy; NuLifeSpan Rx <strong>2018-2024</strong>
                                                                </p>
                                                            </div> :
                                                            <div>
                                                                {
                                                                    showAppleWallet &&
                                                                    <img className="apple-wallet" onClick={this.state.shwoApplePopup ? this.appleWalletPopup : this.openAppleLink} src={AppleWallet} alt="apple icon" />
                                                                }
                                                                {
                                                                    showGoggleWallet &&
                                                                    <img className="google-wallet" onClick={this.openGoogleLink} src={GoogleWallet} src={GoogleWallet} alt="google icon" />
                                                                }
                                                                <p>
                                                                    <a href="https://nulifespanrx.com/" target="_blank" className="no-styling"><strong>www.nulifespanrx.com</strong></a>
                                                                    &copy; NuLifeSpan Rx <strong>2018-2024</strong>
                                                                </p>
                                                            </div>
                                                    }
                                                </div>
                                                <div className="instructions">
                                                    Pharmacist Instructions: This card is preactivated and gives member discounts through the BIN. PCN, GROUP on the front If you need assistance, call 811-800-7820
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        {/*    <div className="content" ref={el => (this.componentRef = el)}>*/}
                        {/*        <div className="image_container printarea" id={card.pcn + "_" + groupnumber + index}>*/}
                        {/*            <div className={'header ' + card.classname}>*/}
                        {/*                <div className="title">*/}
                        {/*                    <span>FREE</span>*/}
                        {/*                    <p>Pharmacy Discount Card</p>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className="card_content">*/}
                        {/*                <div className="left_side">*/}
                        {/*                    <h3 className={classname}>Save up to $95 off on your prescription</h3>*/}
                        {/*                    <div className="groupno_box">*/}
                        {/*                        <div>Group :</div>*/}
                        {/*                        <div>{groupnumber}</div>*/}
                        {/*                    </div>*/}
                        {/*                    <div className="groupno_box">*/}
                        {/*                        <div>BIN  :</div>*/}
                        {/*                        <div>{card.bin}</div>*/}
                        {/*                    </div>*/}
                        {/*                    <div className="groupno_box">*/}
                        {/*                        <div>PCN :</div>*/}
                        {/*                        <div>{card.pcn}</div>*/}
                        {/*                    </div>*/}
                        {/*                    <div className="groupno_box">*/}
                        {/*                        <div>Member ID :</div>*/}
                        {/*                        <div>{"AX" + dateTime.substring(dateTime.length - 6)}</div>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*                <div className="right_side">*/}
                        {/*                    <p>Accepted at 35,000 Pharmacies</p>*/}
                        {/*                    <img src={card_image_pharmacy} alt=""/>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className="disclaimer">This is not Insurance. Discount only. Process all claims electronically.</div>*/}
                        {/*            <div className="instruction">*/}
                        {/*                <div>*/}
                        {/*                    <h4>Member Instructions</h4>*/}
                        {/*                    <ul>*/}
                        {/*                        <li>1. Present card to your pharmacist.</li>*/}
                        {/*                        <li>2. Ask for the discount on each prescription</li>*/}
                        {/*                        <li>3. Start saving up to $95 off your prescription drugs</li>*/}
                        {/*                        <li>4. Use over and over! This card does not expire </li>*/}
                        {/*                    </ul>*/}
                        {/*                </div>*/}
                        {/*                <div className="resize">*/}
                        {/*                    */}{/*<Setup_done groupNumber={this.state.groupNumber} subDomain={this.props.profileData[0].subDomain} />*/}
                        {/*                    <QRCode value={this.state.Qrsubdomain} /> */}{/*<img src={Qrscan} />*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className="instruction noqr">*/}
                        {/*                <div>*/}
                        {/*                    <h4>Member Instructions</h4>*/}
                        {/*                    <ul>*/}
                        {/*                        <li>1. Presente la tarjeta su farmaceutico</li>*/}
                        {/*                        <li>2. Pedir descuento en cada recata</li>*/}
                        {/*                        <li>3. Inicie un aharros de hasta un $95 descuento en los medicamentos con recetal</li>*/}
                        {/*                        <li>4. Inicie un aharros de vezl Esta tarjeta no caduca</li>*/}
                        {/*                    </ul>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <div className="footer">*/}
                        {/*            <p>Pharmacist Instructions : This card is pre-activated and gives member discount through the BIN, PCN and the group on the front. If you need assistance, Call (888) 680 0827</p>*/}
                        {/*            <div>*/}
                        {/*                <a href="https://www.rxhacker.com" target="_blank">www.rxhacker.com</a>*/}
                        {/*                <p>&copy; Rx Hacker 2016-{new Date().getFullYear()}</p>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        </div>
                    )}
                    {
                        this.state.applePopup ? <AppleModal homepagePass={true} closeModal={this.appleWalletPopup.bind(this)} /> : null
                    }
                </>
            )
        
    }
}
    export default CardPartial;